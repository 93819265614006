export const Bio = {
  name: "Shubham Taware",
  roles: [
    "Jr. Software Engineer",
    "Devops and Cloud Enthusiast",
    "Porgrammer",
    "Blogger",
  ],
  description:
    "As a Junior Software Engineer at Cognizant and AWS Certified Cloud Practitioner, I specialize in DevOps and cloud technologies. Skilled in Kubernetes, Ansible, Docker, Jenkins, Git, Helm, and Prometheus, I am a proactive learner committed to optimizing development workflows and staying current with industry trends.",
  github: "https://github.com/shubzz-t",
  resume:
    "https://drive.google.com/file/d/1RaoJ7Q6xtHavaMGdFa1L9asztIYapXGe/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/shubham-taware1",
  twitter: "https://twitter.com/RishavChanda",
  insta: "https://www.instagram.com/shubz_t/",
  facebook: "https://www.facebook.com/rishav.chanda.165/",
  hashnode: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij4KICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMCIgZmlsbD0iIzAwQTNFMDIiLz4KICA8cGF0aCBkPSJNMTIgNmw2IDYtNiw2LTYgNi02IiBmaWxsPSIjRkZGIi8+Cjwvc3ZnPg=="
};

export const skills = [
  {
    title: "DevOps",
    skills: [
      {
        name: "AWS",
        image:
          "https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-icon.svg",
      },
      {
        name: "Kubernetes",
        image:
          "https://www.vectorlogo.zone/logos/kubernetes/kubernetes-icon.svg",
      },
      {
        name: "Docker",
        image:
          "https://www.vectorlogo.zone/logos/docker/docker-icon.svg",
      },
      {
        name: "Jenkins",
        image:
          "https://www.vectorlogo.zone/logos/jenkins/jenkins-icon.svg",
      },
      {
        name: "Ansible",
        image:
          "https://www.vectorlogo.zone/logos/ansible/ansible-icon.svg",
      },
      {
        name: "Terraform",
        image:
          "https://www.vectorlogo.zone/logos/terraformio/terraformio-icon.svg",
      },
      {
        name: "SonarQube",
        image:
          "https://icon.icepanel.io/Technology/svg/SonarQube.svg",
      },
      {
        name: "Scripting",
        image:
          "https://www.vectorlogo.zone/logos/gnu_bash/gnu_bash-icon.svg",
      },
      {
        name: "Git",
        image: "https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg",
      },
      {
        name: "GitHub",
        image:
          "https://www.vectorlogo.zone/logos/github/github-icon.svg",
      },
      {
        name: "GitLab",
        image:
          "https://www.vectorlogo.zone/logos/gitlab/gitlab-icon.svg",
      },
      {
        name: "Jira",
        image:
          "https://www.vectorlogo.zone/logos/atlassian_jira/atlassian_jira-icon.svg",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "Java",
        image:
          "https://www.vectorlogo.zone/logos/java/java-horizontal.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "Postgresql",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },
    ],
  },
 
  {
    title: "Others",
    skills: [
      {
        name: "Ubuntu",
        image:
          "https://www.vectorlogo.zone/logos/ubuntu/ubuntu-icon.svg",
      },
      {
        name: "Windows",
        image:
          "https://www.vectorlogo.zone/logos/microsoft/microsoft-icon.svg",
      },
      {
        name: "Agile",
        image:
          "https://t4.ftcdn.net/jpg/07/49/93/83/240_F_749938331_FHSOcchs0bSdkfAbQaZ900v6OEHwZN3s.jpg",
      },
      {
        name: "Scrum",
        image:
          "https://t4.ftcdn.net/jpg/07/49/93/83/240_F_749938331_FHSOcchs0bSdkfAbQaZ900v6OEHwZN3s.jpg",
      },
      {
        name: "Chef",
        image:
          "https://www.vectorlogo.zone/logos/chefio/chefio-icon.svg",
      },
      {
        name: "YAML",
        image:
          "https://www.vectorlogo.zone/logos/yaml/yaml-icon.svg",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/flipr.jpeg?alt=media&token=1d72532a-45eb-4c1a-a81a-c9bed9fec543",
    role: "Jr. Software Engineer",
    company: "Cognizant Technologies Pvt.Ltd.",
    date: "Jan 2023 - Present",
    desc: "Automation Team:  Authored over 20+ Python scripts and multiple VB Scripts across 5+ projects, boosting efficiency by 30%. User Support:  Resolved issues across 4-5 categories with an 85% resolution rate within SLA using PL/SQL queries.Job Monitoring: Conducted 24/7 surveillance of over 25+ batches and chains with 1000+ jobs daily in Appworx software. Documentation: Documented errors and procedures, reducing downtime by 20%. Server Maintainance: Demonstrated proficiency level of 8/10 across Ubuntu, Windows, and Linux, ensuring 99.9% system integrity.",
    skills: [
      "PL/SQL",
      "Appworx",
      "Banner",
      "Python",
      "Linux",
      "Documentation",
      "ServiceNow",
    ],
    doc: "",
  },
  // {
  //   id: 0,
  //   img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/neurobit.jpeg?alt=media&token=1cacbb7b-e5ea-4efb-9b3d-f5a31b9564ad",
  //   role: "Frontend Engineer Intern",
  //   company: "Neurobit",
  //   date: "June 2023 - Present",
  //   desc: "Working on the frontend of the web application using ReactJS, Redux, and Material UI.",
  //   skills: [
  //     "ReactJS",
  //     "Redux",
  //     "NodeJs",
  //     "Material UI",
  //     "HTML",
  //     "CSS",
  //     "JavaScript",
  //   ],
  //   doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/Screenshot%20from%202023-05-28%2023-20-46.png?alt=media&token=5570f995-e8f4-4f12-bb2f-73bcf4b20730",
  // },
  // {
  //   id: 1,
  //   img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/flipr.jpeg?alt=media&token=1d72532a-45eb-4c1a-a81a-c9bed9fec543",
  //   role: "Fullstack Externship",
  //   company: "Flipr",
  //   date: "June 2023 - July 2023",
  //   desc: "Built an employee management full stack web app used Docker and deployed on AWS ec2. I was the top performer in the program.",
  //   skills: [
  //     "ReactJS",
  //     "Redux",
  //     "NodeJs",
  //     "Material UI",
  //     "HTML",
  //     "CSS",
  //     "JavaScript",
  //     "Docker",
  //     "AWS",
  //     "MongoDB",
  //   ],
  // },
  // {
  //   id: 2,
  //   img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/girlScript.jpeg?alt=media&token=e656a621-cf3c-4230-bf0f-e74b4cec6035",
  //   role: "Open Source Contributor ",
  //   company: "GirlScript Summer of Code",
  //   date: "May 2023 - Present",
  //   desc: "Contributing to different open-source projects and learn from industry experts",
  //   doc: "https://media.licdn.com/dms/image/D4D22AQGzSpgFaR4hsQ/feedshare-shrink_800/0/1684079632872?e=1687996800&v=beta&t=i0xWn_k32Oj9SxKfc6rARSbOghXD9Ge6WyEb2p2vvrc",
  // },
  // {
  //   id: 3,
  //   img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/gdsc.jpeg?alt=media&token=c162329c-efaa-4be8-a173-8d3f4c48ea70",
  //   role: "Android Developer",
  //   company: "DSC KIIT",
  //   date: "Nov2021 - Present",
  //   desc: "As an Android developer at the Google Developers Student Club (GDCS), I have had the opportunity to work on exciting projects and collaborate with talented developers who share my passion for technology. Through my involvement with GDCS, I have also had the opportunity to host and participate in numerous events, including hackathons, study jams, and workshops.",
  //   skills: [
  //     "Leadership",
  //     "Mobile Application Development",
  //     "Kotlin",
  //     "XML",
  //     "Figma",
  //   ],
  // },
  // {
  //   id: 4,
  //   img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Rudraksha.jpeg?alt=media&token=8f83f41e-d0a1-486f-9c7f-dd4cd1d09e8d",
  //   role: "Android Developer Intern",
  //   company: "Rudraksha Welffare Foundation",
  //   date: "June 2021 - Oct 2021",
  //   desc: "As an Android Developer Intern at Rudraksha Welfare Foundation from June 2021 to October 2021, I gained valuable hands-on experience in application development. During my tenure, I contributed to the development of the application's back-end cloud functions using Firebase and implemented a front-end with the MVVM structure, which was designed using Figma. I also added a one-to-one video call feature, conceptualized new features, and optimized app performance. This internship helped me enhance my skills in Android development and gave me an opportunity to work in a collaborative team environment.",
  //   skills: [
  //     "Android",
  //     "Java",
  //     "Kotlin",
  //     "XML",
  //     "Node Js",
  //     "Cloud Firestore",
  //     "Firebase",
  //     "Figma",
  //   ],
  //   doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/WhatsApp%20Image%202023-05-05%20at%2012.07.39%20AM.jpeg?alt=media&token=9f0e1648-568b-422d-bd0b-1f125f722245",
  // },
];

export const education = [
  {
    id: 0,
    img: "http://www.unipune.ac.in/uop_files/uop_logo.jpg",
    school: "Asian College of Arts, Science and Commerce, Pune",
    date: "June 2022 - Jun 2024",
    grade: "76.55%",
    desc: "I have completed my Masters in Computer Applications where I have studied about various subjects including Java, Data Science, Android, Python and much more",
    degree: "Bachelor of Technology - BTech, Computer Science and Engineering",
  },
  {
    id: 1,
    img: "https://vidyapratishthan.com/Site/wp-content/uploads/2022/10/vpmain_white.fw_.png",
    school: "Vidya Pratishthan Arts, Science and Commerce College Baramati",
    date: "Apr 2019 - Apr 2022",
    grade: "90.00%",
    desc: "I completed my Bachelors in Computer Science by securing 90% where I learn C programming, Java, Python, Networking, Operating Systems etc",
    degree: "ISC(XII), Science with Computer",
  },
  // {
  //   id: 2,
  //   img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/methodist.png?alt=media&token=018a1b18-e2dd-4f34-8855-cff1b6b69fd3",
  //   school: "Methodist School, Dankuni",
  //   date: "Apr 2017 - Apr 2019",
  //   grade: "92.3%",
  //   desc: "I completed my class 10 education at Methodist School, Dankuni, where I studied Science with Computer Application.",
  //   degree: "ICSC(X), Science with Computer",
  // },
];

export const projects = [
  {
    id: 0,
    title: "Building a Production-Ready CI/CD Pipeline",
    date: "Aug 2024 - Aug 2024",
    description:
      `Built a production-level CI/CD pipeline to automate the entire process of developing and deploying a Java web application. The pipeline handles everything from managing code to deploying it in both development and production environments. We used a custom domain to make the deployed application easily accessible.
Acheivements:
Automated Deployments: Fully automated deployments for both development and production, eliminating manual work.
Faster Delivery: Reduced deployment time by 40%, accelerating feature releases.
Improved Code Quality & Security: Enhanced code quality with SonarQube, reducing issues by 30%. Cut security risks by 80% using Trivy scans.
Consistent Deployments: Achieved 95% build consistency with Docker, ensuring smooth cross-environment runs.
Scalable Infrastructure: Deployed a reliable, zero-downtime Kubernetes cluster ( EKS ) with Terraform.
Continuous Monitoring: Implemented 24/7 monitoring, ensuring 99.9% uptime using Blackbox, Prometheus and Grafana.
Custom Domain Hosting: Deployed the application on a custom domain for easy access using GoDaddy.`,
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1724498626817/a69b8e50-8a9f-4495-9c42-11c577752ed0.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    tags: [
      "GitHub",
      "Jenkins",
      "Maven",
      "SonarQube",
      "Nexus",
      "Docker",
      "Terraform",
      "AWS",
      "Kubernetes ( EKS )",
      "Prometheus",
      "Grafana",
    ],
    category: "DevOps",
    github: "https://github.com/shubzz-t/Blogging_App_Java.git",
    webapp: "https://shubzz.hashnode.dev/building-a-production-ready-cicd-pipeline-a-comprehensive-guide-to-devops",
  },
  {
    id: 1,
    title: "Building a Robust CI/CD Pipeline with GitHub Actions for Multi-Cluster Deployment",
    date: "Aug 2024 - Aug 2024",
    description:
      `CI/CD Pipelines: Created automated workflows for the dev and main branches using GitHub Actions.
Build & Deploy: Automated the build and deployment processes with Maven, Docker, and Kubernetes.
Cluster Deployments: Deployed code from the dev branch to a Kubeadm cluster and from the main branch to an EKS cluster.
Quality & Security: Integrated Trivy for security scanning and SonarQube for code quality analysis.

Achievements:
Branches: Automated deployment for dev and main branches, reducing manual intervention by 100%.
Build Time: Decreased build time by 75% using GitHub Actions for continuous integration.
Vulnerability Reduction: Achieved a 90% reduction in critical vulnerabilities with Trivy scanning.
Code Coverage: Enhanced code coverage reporting, increasing visibility into code quality by 85%.
Reduced deployment time by 60% through automated Docker image builds and Kubernetes deployments.
Accelerated turnaround time from code commit to deployment by 40%.`,
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1722855351929/abb3b921-9363-4866-b3b7-e3f40ed4dffe.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    tags: [
      "GitHub",
      "Jenkins",
      "Maven",
      "SonarQube",
      "Nexus",
      "Trivy",
      "Docker",
      "AWS",
      "Kubernetes ( Kubeadm )",
      "Prometheus",
      "Grafana",
    ],
    category: "DevOps",
    github: "https://github.com/shubzz-t/Multi_Cluster_Java_Project.git",
    webapp: "https://shubzz.hashnode.dev/building-a-robust-cicd-pipeline-with-github-actions-for-multi-cluster-deployment",
  },
  {
    id: 2,
    title: "Deploying Netflix Clone on EKS using DevSecOps Pipeline",
    date: "Jul 2024 - Jul 2024",
    description:
      `Deployed Netflix clone application on Kubernetes (EKS) using Jenkins-based CI/CD pipeline.
Integrated SonarQube and Trivy for continuous security scanning.
Implemented Prometheus and Grafana for monitoring, achieving 99.9% uptime.
Managed Kubernetes clusters and automated deployment with ArgoCD.

Technologies Used:
Tools: Jenkins, GitHub, Docker, SonarQube, Trivy, Prometheus, Grafana, Argocd.
Platforms: AWS EKS

Key Achievements:
Successfully deployed the application on EKS ensuring seamless integration and scalability.
Implemented SonarQube and Trivy for continuous security scanning, achieving a 9/10 security rating.
Established Jenkins for automated builds and deployments, reducing deployment time by 40%.
Configured Prometheus and Grafana for comprehensive monitoring, ensuring 99.9% uptime and proactive issue resolution.
Set up email notifications for critical alerts, improving incident response time by 30%.`,
    image:
      "https://github.com/shubzz-t/DevSecOps-Project-Netflix-Clone/raw/main/public/assets/home-page.png",
    tags: [
      "GitHub",
      "Jenkins",
      "SonarQube",
      "Trivy",
      "Docker",
      "AWS",
      "Kubernetes ( EKS )",
      "Prometheus",
      "Grafana",
    ],
    category: "DevOps",
    github: "https://github.com/shubzz-t/DevSecOps-Project-Netflix-Clone.git",
    webapp: "https://shubzz.hashnode.dev/building-a-robust-cicd-pipeline-with-github-actions-for-multi-cluster-deployment",
  },
  {
    id: 3,
    title: "Ultimate Boardgame CI/CD Pipeline",
    date: "Jul 2024 - Jul 2024",
    description:
      `Developed a robust CI/CD pipeline for a Java-based board game. Integrated tools like GitHub, Jenkins, Maven, Nexus, Trivy, Docker, Kubernetes, Kubeaudit, SonarQube, Prometheus, and Grafana. Achieved 60% reduction in deployment downtime, 95% security compliance, and improved system reliability by 35% through real-time monitoring.

GitHub Integration: Implemented version control and collaboration, achieving 100% code transparency and tracking.
Jenkins Automation: Automated the build and deployment process, reducing manual efforts by 70%.
Maven Build Management: Ensured efficient project build and dependency management, reducing build time by 30%.
Nexus Repository Management: Set up a central repository for managing artifacts, improving dependency resolution speed by 25%.
Trivy Integration: Scanned files and images for vulnerabilities, ensuring 95% security compliance.
SonarQube Integration: Conducted static code analysis, reducing code vulnerabilities by 50%.
Kubeaudit Implementation: Performed cluster security scanning, improving cluster security posture by 40%
Docker Utilization: Created and pushed Docker images, achieving 100% consistency across environments.
Kubernetes Deployment: Deployed the application on a Kubeadm cluster, reducing downtime by 60%.
Prometheus and Grafana Monitoring: Implemented real-time monitoring with Prometheus and Grafana, improving system reliability by 35%.
Node Exporter and Blackbox Exporter: Monitored node and application performance, ensuring 99.9% uptime.`,
    image:
      "https://journeyofquality.com/wp-content/uploads/2020/11/cicd-tools.jpg",
    tags: [
      "GitHub",
      "Jenkins",
      "Maven",
      "Nexus",
      "Trivy",
      "Docker",
      "Kubernetes",
      "kubeaudit",
      "Sonarqube",
      "Prometheus",
      "Grafana",
    ],
    category: "DevOps",
    github: "https://github.com/shubzz-t/BoardGame.git",
    webapp: "",
  },
  {
    id: 4,
    title: "Enhancing Fleet Management : A Deep Dive into Fleetman App Microservices Deployment",
    date: "Jun 2024 - Jun 2024",
    description:
      `Fleetman is an advanced fleet management application that uses microservices for real-time vehicle tracking and data analytics. I developed and integrated several key components: the Simulator Service for 95% accurate position updates, Apache MQ for efficient message handling with a 20% reduction in processing delays, and MongoDB for reliable data storage with 99.9% availability. The Position Tracker Service ensured 15% faster data retrieval, while the API Gateway Service cut response times by 30%. I also designed a user-friendly Angular front-end with a 90% satisfaction rating, implemented a CI/CD strategy reducing downtime by 40%, and established 24/7 system monitoring, reducing outages by 50% and achieving 99.7% uptime.`,
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1719133541875/c19e2271-23e5-4583-b485-82851315b393.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    tags: [
      "GitHub",
      "Jenkins",
      "Kubernetes (EKS)",
      "Microservices",
    ],
    category: "DevOps",
    github: "https://github.com/orgs/shubz-fleetman-org/repositories",
    webapp: "https://shubzz.hashnode.dev/enhancing-fleet-management-a-deep-dive-into-fleetman-app-deployment",
  },
  {
    id: 5,
    title: "Jenkins CI/CD Simplified",
    date: "Mar 2024 - Mar 2024",
    description:
      `This project involved the integration of various DevOps tools to create a streamlined CI/CD pipeline for Spring Boot applications. The primary tools used included Jenkins, SonarQube, Docker, and ArgoCD. The goal was to automate the build, test, and deployment processes to enhance efficiency, ensure code quality, and minimize downtime.

Key Achievements:
Integrated DevOps Tools: Successfully integrated Jenkins, SonarQube, Docker, and ArgoCD to create a cohesive DevOps toolchain.
Streamlined CI/CD Pipeline: Developed and implemented a CI/CD pipeline for Spring Boot applications, reducing deployment time by 40%.
Automation and Orchestration: Leveraged Jenkins for automation and orchestration, resulting in a 50% reduction in manual intervention.
Code Quality Assurance: Ensured code quality and consistency by integrating SonarQube, leading to a 30% reduction in code defects.
Containerized Deployments: Implemented Docker for containerizing applications, enhancing deployment consistency and scalability by 35%.
Kubernetes Deployments: Automated Kubernetes deployments using ArgoCD, achieving a 25% decrease in deployment errors and a 20% increase in deployment speed.
Expertise in AWS and Minikube: Gained proficiency in setting up AWS EC2 instances and deploying applications on Minikube, enhancing cloud deployment skills.
Performance Improvement: Transformed development practices with seamless workflows, resulting in a 30% improvement in overall system performance.
Downtime Reduction: Reduced system downtime by 20% through automated monitoring and deployment processes.`,
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1709638778325/291ba095-74c6-48cc-bbe0-5b1b063af5e8.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    tags: [
      "GitHub",
      "Jenkins",
      "Maven",
      "SonarQube",
      "ArgoCD",
      "Docker",
      "Kubernetes (Minikube)",
    ],
    category: "DevOps",
    github: "https://github.com/shubzz-t/java-maven-sonar-argocd-k8s.git",
    webapp: "https://shubzz.hashnode.dev/jenkins-cicd-simplified",
  },



//   {
//     id: 0,
//     title: "Building a Robust CI/CD Pipeline with GitHub Actions for Multi-Cluster Deployment",
//     date: "Aug 2024 - Aug 2024",
//     description:
//       `CI/CD Pipelines: Created automated workflows for the dev and main branches using GitHub Actions.
// Build & Deploy: Automated the build and deployment processes with Maven, Docker, and Kubernetes.
// Cluster Deployments: Deployed code from the dev branch to a Kubeadm cluster and from the main branch to an EKS cluster.
// Quality & Security: Integrated Trivy for security scanning and SonarQube for code quality analysis.

// Achievements:
// Branches: Automated deployment for dev and main branches, reducing manual intervention by 100%.
// Build Time: Decreased build time by 75% using GitHub Actions for continuous integration.
// Vulnerability Reduction: Achieved a 90% reduction in critical vulnerabilities with Trivy scanning.
// Code Coverage: Enhanced code coverage reporting, increasing visibility into code quality by 85%.
// Reduced deployment time by 60% through automated Docker image builds and Kubernetes deployments.
// Accelerated turnaround time from code commit to deployment by 40%.`,
//     image:
//       "",
//     tags: ["React Js", "MongoDb", "Node Js", "Express Js", "Redux"],
//     category: "web app",
//     github: "https://github.com/rishavchanda/Podstream",
//     webapp: "https://podstream.netlify.app/",
//     member: [
//       {
//         name: "Rishav Chanda",
//         img: "https://avatars.githubusercontent.com/u/64485885?v=4",
//         linkedin: "https://www.linkedin.com/in/rishav-chanda-b89a791b3/",
//         github: "https://github.com/rishavchanda/",
//       },
//       {
//         name: "Upasana Chaudhuri",
//         img: "https://avatars.githubusercontent.com/u/100614635?v=4",
//         linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
//         github: "https://github.com/upasana0710",
//       },
//     ],
//   },
//   {
//     id: 1,
//     title: "Vexa",
//     date: "Oct 2022 - Present",
//     description:
//       "Designed and developed the Vexa project, a project management app that helps users and teams stay organized and on track. Implemented key features such as task tracking, team collaboration, and time tracking to improve productivity and project outcomes and also invite team/project members. The plan is to add a community of users where they can find developers and other team members and chat system implementation.",
//     image:
//       "https://user-images.githubusercontent.com/64485885/234916413-96296f13-fe4b-4cc4-b215-e72bd7c27928.png",
//     tags: [
//       "React Js",
//       "MongoDb",
//       "Node Js",
//       "Express Js",
//       "Redux",
//       "NodeMailer",
//     ],
//     category: "web app",
//     github: "https://github.com/rishavchanda/Project-Management-App",
//     webapp: "https://vexa-app.netlify.app/",
//   },
//   {
//     id: 2,
//     title: "Brain Tumor Detection",
//     date: "Jan 2023 - Mar 2023",
//     description:
//       "Preprocessed and augmented the dataset to improve model accuracy, trained the model, created API using model and Python, and used React web app for the project's front end. Achievements: Achieved an accuracy of 99.2% to accurately detect brain tumors from medical images.",
//     image:
//       "https://github.com/rishavchanda/Brain-Tumor-Detection/raw/main/Readme_resource/Image2.png",
//     tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
//     category: "machine learning",
//     github: "https://github.com/rishavchanda/Brain-Tumor-Detection",
//     webapp: "https://brain-tumor.netlify.app/",
//     member: [
//       {
//         name: "Rishav Chanda",
//         img: "https://avatars.githubusercontent.com/u/64485885?v=4",
//         linkedin: "https://www.linkedin.com/in/rishav-chanda-b89a791b3/",
//         github: "https://github.com/rishavchanda/",
//       },
//       {
//         name: "Upasana Chaudhuri",
//         img: "https://avatars.githubusercontent.com/u/100614635?v=4",
//         linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
//         github: "https://github.com/upasana0710",
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: "Buckoid",
//     date: "Dec 2021 - Apr 2022",
//     description:
//       "App Is Currently In Playstore 100+ Downloads. This Project proposes an “Expense Tracking App”. Keep track of your personal expenses and compare them to your monthly income with the budget planner. It has Google Drive Cloud API for Backup of User Room Database. Made with Kotlin in MVVM Architecture & Live Data.",
//     image:
//       "https://camo.githubusercontent.com/fbf405040ae86b5d4a40f24e4ac034982cb8c0e2d850560ba64527997b258be8/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f6c6f67696e2d65613565322e61707073706f742e636f6d2f6f2f4255434b4f49442532302831292e706e673f616c743d6d6564696126746f6b656e3d32653735376235372d323964372d346263612d613562322d653164346538313432373435",
//     tags: ["Kotlin", "MVVM", "Room Database", "Google Drive Cloud API"],
//     category: "android app",
//     github: "https://github.com/rishavchanda/Buckoid-Android-App",
//     webapp: "https://play.google.com/store/apps/details?id=com.rishav.buckoid",
//   },
//   {
//     id: 10,
//     title: "Job Finding App",
//     date: "Jun 2023 - Jul 2023",
//     description:
//       "A Job Finding App made with React Native, Axios. Users can search for any job coming from API and apply there.",
//     image:
//       "https://user-images.githubusercontent.com/64485885/255237090-cf798a2c-1b41-4bb7-b904-b5353a1f08e8.png",
//     tags: ["React Native", "JavaScript", "Axios"],
//     category: "android app",
//     github: "https://github.com/rishavchanda/Job-finder-App",
//     webapp: "https://github.com/rishavchanda/Job-finder-App",
//   },
//   {
//     id: 4,
//     title: "Whatsapp Clone",
//     date: "Jul 2021",
//     description:
//       "A WhatsApp clone made with React JS, Firebase, and Material UI. It has Phone Authentication, Real-time Database. It has a chat room where users can chat with each other. It has a sidebar where users can see all the chat rooms and can create a new chat room. It has a login page where users can log in with their Google account.",
//     image:
//       "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-rishav.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
//     tags: ["React Js", "Firebase", "Firestore", "Node JS"],
//     category: "web app",
//     github: "https://github.com/rishavchanda/Whatsapp-Clone-React-Js",
//     webapp: "https://whatsapp-clone-rishav.web.app",
//   },
//   {
//     id: 5,
//     title: "Todo Web App",
//     date: "Jun 2021",
//     description:
//       " A Todo Web App made with React JS, Redux, and Material UI. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the tasks and can create a new task. It has a calendar where users can see all the tasks on a particular date. It has a search bar where users can search for a particular task.",
//     image:
//       "https://camo.githubusercontent.com/a328255ad96f861f57d25096d28018ab2656c689a1456b0d145764009bed2d1a/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f74253230283938292e706e673f616c743d6d6564696126746f6b656e3d33643335646366322d626666322d343730382d393031632d343232383866383332386633",
//     tags: ["React Js", "Local Storage", "AWS Auth", "Node JS"],
//     category: "web app",
//     github: "https://github.com/rishavchanda/Todo-Web-App",
//     webapp: "https://rishav-react-todo.netlify.app/",
//   },
//   {
//     id: 6,
//     title: "Breaking Bad",
//     date: "Jun 2021",
//     description:
//       "A simple react app that shows the characters of the famous TV series Breaking Bad. It uses the Breaking Bad API to fetch the data. It also has a search bar to search for a particular character.",
//     image:
//       "https://camo.githubusercontent.com/cd07010cbeb90cb1b43a5d6162784326aef02210ef7d41a0f9ae043b3e392378/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f7425323028313534292e706e673f616c743d6d6564696126746f6b656e3d65613439383630632d303435362d343333342d616435372d336239346663303333363263",
//     tags: ["React Js", "API", "Axios", "Node JS"],
//     category: "web app",
//     github: "https://github.com/rishavchanda/Breaking-Bad",
//     webapp: "https://breaking-bad-webapp.netlify.app",
//   },
//   {
//     id: 7,
//     title: "Quiz App",
//     date: "Dec 2020 - Jan 2021",
//     description:
//       "A android quiz app made with Java and Firebase. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the quiz categories and can create a new quiz. It has a leaderboard where users can see the top 10 scorers. It has a search bar where users can search for a particular quiz.",
//     image:
//       "https://github-production-user-asset-6210df.s3.amazonaws.com/64485885/239726262-c1b061d1-d9d0-42ef-9f1c-0412d14bc4f6.gif",
//     tags: ["Java", "Android Studio", "Firebase", "Google Auth"],
//     category: "android app",
//     github: "https://github.com/rishavchanda/Quiz-Earn",
//     webapp: "https://github.com/rishavchanda/Quiz-Earn",
//   },
//   {
//     id: 8,
//     title: "Face Recognition",
//     date: "Jan 2021",
//     description:
//       "A Face recognition python app made with OpenCV. It uses face_recognition library to detect faces. It uses the webcam to detect faces. It also has a search bar to search for a particular face.",
//     image:
//       "https://camo.githubusercontent.com/a8b3e1ebf26d4c95f75bc6671189a3590eb67eb8332d7c7452492990e731efb9/68747470733a2f2f77617265686f7573652d63616d6f2e696e67726573732e636d68312e707366686f737465642e6f72672f356137383335396561323762643261633232336437656662306639303831306437373930383436312f363837343734373037333361326632663633366336663735363432653637363937343638373536323735373336353732363336663665373436353665373432653633366636643266363137333733363537343733326633383339333633363339333232663332333433343333333033333339333832663333333636363330363533333636333032643331333336333632326433313331363533373264333833323335333832643334363433303633333936333635333136353334333133393265363736393636",
//     tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
//     category: "machine learning",
//     github: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
//     webapp: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
//   },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
